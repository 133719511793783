export function saveAuthToken(token) {
    localStorage.setItem('authToken', token);
    localStorage.setItem('authExpiry', (Date.now() + 86400000).toString());
}

export function removeAuthToken() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authExpiry');
}

export function getAuthToken(){
    try{
        const storedToken = localStorage.getItem('authToken');
        const storedExpiry = localStorage.getItem('authExpiry');
        if (storedToken && storedExpiry && Date.now() < parseInt(storedExpiry)) {
            return storedToken
        } else {
            return undefined
        }
    }catch(e){
        console.log(e)
        return undefined
    }

}