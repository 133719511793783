import { createContext, useState } from "react";

export const AppContext = createContext();

export const WebAppProvider = ({ children }) => {
  const [isMuted, setMuted] = useState(false);
  const [modalPhase, setModalPhase] = useState(0);
  const [balance, setBalance] = useState(0);
  const value = {
    isMuted,
    setMuted,
    modalPhase,
    setModalPhase,
    balance,
    setBalance,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
