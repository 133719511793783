import React from "react";
import logo from "../../Assets/logo.png";

const HowToPlay = ({ isOpen }) => {
  return (
    <div
      className={
        isOpen === 2
          ? "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[500px] w-[400px] bg-[#262626] border-[1px] border-[#A4A4A4] z-50"
          : "hidden"
      }
    >
      <p className="text-white text-4xl flex justify-center items-center pt-2">
        HOW TO PLAY
      </p>

      <div className="text-[20px] text-white px-10 flex flex-col justify-center items-start h-[400px] pt-10">
        <p className="pt-6">
          1. ADD TO YOUR BALANCE BY NAVIGATING TO THE DEPOSIT AREA AND
          DEPOSITING $GAMBA
        </p>
        <p className="pt-6">2. SELECT HEADS OR TAILS</p>
        <p className="pt-6">3. ENTER AMOUNT YOU WISH TO WAGER</p>
        <p className="pt-6">4. PRESS FLIP & GOOD LUCK !</p>
        <img src={logo} alt="gamba-logo" className="w-[200px] mx-auto pt-10" />
      </div>
    </div>
  );
};

export default HowToPlay;
