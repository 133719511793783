import React, { useEffect, useContext } from "react";
import Header from "./Components/Header/Header";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Home from "./Routes/HomePage/Home";
import CoinFlipPage from "./Routes/CoinFlipPage/CoinFlipPage";
import LeaderBoardPage from "./Routes/LeaderBoardPage/LeaderBoardPage";
import BankModal from "./Components/Bank/BankModal";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import axios from "axios";
import { AppContext } from "./Context/app.context";
import { getAuthToken, saveAuthToken } from "./authentificationHelper";
import RoulettePage from "./Routes/RoulettePage/RoulettePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { setBalance } = useContext(AppContext);

  // ======================= 🖋️ SIGN MESSAGE 💬 ========================
  const { signMessage } = useSignMessage({
    message:
      "Welcome to the Gamba casino!\n\nThis message is only used to verify that you are owner of this wallet!",
    onError() {
      disconnect();
    },
    onSuccess(data) {
      axios
        .post(
          `https://api.gambacoin.com/verifySignature`,
          {
            walletAddress: address,
            signature: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          saveAuthToken(response.data.authorization);
          setUser();
        })
        .catch(() => {
          disconnect();
        });
    },
  });
  // =============== 🔎 MESSAGE SIGNING VERIFICATION ⛔ ================
  async function setUser() {
    await axios
      .get(`https://api.gambacoin.com/getUser`, {
        headers: {
          authorization: getAuthToken(),
        },
      })
      .then(async (response) => {
        setBalance(response.data.balance);
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (!getAuthToken()) {
      if (isConnected) {
        signMessage();
      }
    } else {
      setUser();
    }
  }, [isConnected, address]);

  return (
    <div className="bg-[#195238] h-screen font-gothic">
      <BrowserRouter>
        <ScrollToTop />
        <BankModal />
        {/* DARK FADE TOP SCREEN */}
        <div className="bg-black fadeBox fixed top-0 w-full z-10" />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="coinflip" element={<CoinFlipPage />} />
          <Route exact path="leaderboard" element={<LeaderBoardPage />} />

          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
//<Route exact path="roulette" element={<RoulettePage />} />
