import React, { useState } from "react";
import { CoinFlipConnect } from "../../Components/Buttons/CoinFlipConnect";
import { useAccount } from "wagmi";
import BetArea from "../../Components/CoinFlip/BetArea";
import Faq from "../../Components/FAQ/Faq";
import coin from "../../Assets/coin.png";
import HowToPlay from "../../Components/HowToPlay/HowToPlay";

const CoinFlipPage = () => {
  const [isOpen, setOpen] = useState(0);
  const { isConnected } = useAccount();

  return (
    <div className="pt-[102px] bg-[#195238]">
      <div
        onClick={() => setOpen(0)}
        className={
          isOpen === 0
            ? "hidden"
            : "fixed bg-black/50 w-full h-full top-0 z-10 mt-10"
        }
      />

      <div className="text-white text-4xl text-center pb-8">
        #1 MOST TRUSTED PLACE TO <br />
        GAMBLE SHITCOINS
      </div>
      {isConnected ? (
        <></>
      ) : (
        <img src={coin} alt="gamba-logo" className="h-52 mx-auto mb-10 mt-5" />
      )}

      <div className="flex justify-center items-center font-bold pt-6 pb-24">
        {isConnected ? <BetArea /> : <CoinFlipConnect />}
      </div>

      <div className="flex text-white text-1xl justify-center ">
        There is a 2.5% fee on every bet!
      </div>

      <div className=" flex gap-4 text-white  justify-center ">
        <p className="underline cursor-pointer" onClick={() => setOpen(1)}>
          FAQ
        </p>
        <p>|</p>
        <p className="underline cursor-pointer" onClick={() => setOpen(2)}>
          HOW TO PLAY
        </p>
      </div>

      {/* <div
        onClick={() => setOpen(0)}
        className={
          isOpen === 0
            ? "hidden"
            : "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[500px] w-[400px] bg-[#262626] border-[1px] border-[#A4A4A4] z-50"
        }
      >
        <p className="text-black">xxxxx</p>
      </div> */}
      <Faq setOpen={setOpen} isOpen={isOpen} />
      <HowToPlay setOpen={setOpen} isOpen={isOpen} />
    </div>
  );
};

export default CoinFlipPage;
