import React, { useEffect, useState, useContext } from "react";
import Button from "../Buttons/Button";
import homelogo from "../../Assets/headerlogo.png";
import { toast } from "react-toastify";
import {
  useAccount,
  useBalance,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractReads,
} from "wagmi";
import { formatEther, parseEther } from "ethers/lib/utils.js";
import { getAuthToken } from "../../authentificationHelper";
import {
  casinoContractABI,
  casinoContractAddress,
} from "../../Utils/Casino/casinoConstant";
import {
  tokenContractABI,
  tokenContractAddress,
} from "../../Utils/Gamba/tokenConstant";
import axios from "axios";
import { AppContext } from "../../Context/app.context";

const Deposit = ({ setModalPhase }) => {
  const [depositAmount, setDepositAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState(0);
  const [isApproved, setApproved] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const { address } = useAccount();
  const { setBalance } = useContext(AppContext);
  let toastId = React.useRef(null);

  // ================== 📚 CONTRACT READS 📚 ===================
  // $GAMBA Balance Contract Reads
  const { data, refetch: refetchReads } = useContractReads({
    contracts: [
      {
        address: tokenContractAddress,
        abi: tokenContractABI,
        functionName: "balanceOf",
        args: [address],
      },
      {
        address: tokenContractAddress,
        abi: tokenContractABI,
        functionName: "allowance",
        args: [address, casinoContractAddress],
      },
    ],
    // watch: true,
    onSuccess(data) {
      setMaxAmount(formatEther(data?.[0]));
      if (formatEther(data?.[1]?.toString()) > 0) {
        setApproved(true);
      }
    },
  });

  // console.log("approvedAmount:", approvedAmount);
  // console.log("maxAmount:", maxAmount);
  // console.log("depositAmount:", depositAmount);
  // console.log(Number(approvedAmount) < Number(depositAmount));
  // console.log(typeof depositAmount);
  // console.log("casinoAddy", casinoContractAddress);

  // ================== 📝 CONTRACT WRITES 📝 ===================
  // $GAMBA Deposit Contract Write
  const { config: casinoConfig } = usePrepareContractWrite({
    address: casinoContractAddress,
    abi: casinoContractABI,
    functionName: "deposit",
    args: [parseEther(depositAmount || "0")],
  });
  const {
    write: makeDeposit,
    data: depositData,
    isSuccess: isDepositStarted,
  } = useContractWrite(casinoConfig);
  const { isSuccess: depositTxSuccess } = useWaitForTransaction({
    hash: depositData?.hash,
    onSuccess(data) {
      // console.log(data);
      // axios
      //   .post(
      //     `https://api.gambacoin.com/deposit`,
      //     {
      //       amount: Number(depositAmount),
      //       transactionHash: data.transactionHash,
      //     },
      //     {
      //       //TODO: change authorization back
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: getAuthToken(),
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     console.log(response);
      //     setBalance(response.data.updatedBalance);
      //     successfullyDeposited();
      //   })
      //   .catch((error) => {
      //     // disconnect();
      //     console.log(error);
      //   });
    },
  });

  // $GAMBA Token Approve Contract Write
  const { config: tokenConfig } = usePrepareContractWrite({
    address: tokenContractAddress,
    abi: tokenContractABI,
    functionName: "approve",
    args: [casinoContractAddress, parseEther(depositAmount || "0")],
  });
  const {
    write: approveToken,
    data: approveData,
    isSuccess: isApprovalStarted,
  } = useContractWrite(tokenConfig);
  const { isSuccess: approveTxSuccess } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  // =============== 🔄 PROCESSING & HANDLING 🛠 ================
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const updateUserBal = async () => {
    await delay(10000);
    await axios
      .get(`https://api.gambacoin.com/getUser`, {
        headers: {
          authorization: getAuthToken(),
        },
      })
      .then(async (response) => {
        setBalance(response.data.balance);
      })
      .catch(() => {});
  };
  // async function setUser() {
  //   await axios
  //     .get(`https://api.gambacoin.com/getUser`, {
  //       headers: {
  //         authorization: getAuthToken(),
  //       },
  //     })
  //     .then(async (response) => {
  //       setBalance(response.data.balance);
  //     })
  //     .catch(() => {});
  // }

  const handleChange = (event) => {
    setDepositAmount(event.target.value);
  };

  const handleMax = () => {
    setDepositAmount(Number(maxAmount).toFixed(0));
  };

  // const handleDeposit = () => {
  //   //depositAmount the amount of tokens the user entered
  //   //address the address of the user
  //   //https://wagmi.sh/react/getting-started
  //   if (Number(formatEther(data?.[1]?.toString())) >= Number(depositAmount)) {
  //     // makeDeposit?.();
  //   } else {
  //     approveToken?.();
  //   }
  // };

  const handleApprove = () => {
    approveToken?.();
  };

  useEffect(() => {
    if (isDepositStarted) {
      pendingDeposit();
    }
  }, [isDepositStarted]);

  useEffect(() => {
    if (isApprovalStarted) {
      pendingApproval();
    }
  }, [isApprovalStarted]);

  useEffect(() => {
    if (depositTxSuccess) {
      const fetchData = async () => {
        await refetchReads();
        // pendingConfirmations();
        successfullyDeposited();
        updateUserBal();
      };
      fetchData();
    }
  }, [depositTxSuccess]);

  useEffect(() => {
    if (approveTxSuccess) {
      const fetchReadData = async () => {
        await refetchReads();
        successfullyApproved();
      };
      fetchReadData();
    }
  }, [approveTxSuccess]);

  // ================ 🍞 TOAST / CHECKOUT 🛒 =================
  const pendingDeposit = () => {
    toastId.current = toast.loading(
      "PROCESSING DEPOSIT, DO NOT CLOSE THIS PAGE",
      {
        position: "top-center",
        theme: "dark",
        bodyClassName: "toastBodyPending",
      }
    );
  };

  const pendingApproval = () => {
    setApprovalLoading(true);
    toastId.current = toast.loading("APPROVING...", {
      position: "top-center",
      theme: "dark",
      bodyClassName: "toastBodyPending",
    });
  };

  // const pendingConfirmations = () => {
  //   toast.dismiss(toastId.current);
  //   toastId.current = toast.loading(
  //     "WAITING FOR TRANSACTION TO BE VALIDATED, DO NOT CLOSE THIS PAGE",
  //     {
  //       position: "top-center",
  //       theme: "dark",
  //       bodyClassName: "toastBodyPending",
  //     }
  //   );
  // };

  const successfullyApproved = () => {
    setApprovalLoading(false);
    toast.dismiss(toastId.current);
    toastId.current = toast.success(
      "APPROVAL SUCCESSFUL, YOU MAY PROCEED WITH YOUR DEPOSIT !",
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        bodyClassName: "toastBodySuccess",
      }
    );
  };

  const successfullyDeposited = () => {
    toast.dismiss(toastId.current);
    toastId.current = toast.success(
      "YOUR DEPOSIT HAS BEEN CONFIRMED YOUR BALANCE WILL UPDATE SHORTLY !",
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        bodyClassName: "toastBodySuccess",
      }
    );
  };

  return (
    <div>
      <div className="bg-[#262626] text-white rounded-3xl px-3 w-[90%] max-w-lg border-[1px] border-[#A4A4A4] text-[17px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        {/* MODAL HEADER */}
        <div className="pb-6">
          <img
            src={homelogo}
            alt="gamba-logo"
            className="w-8 h-8 relative top-4 left-4"
          />
          <Button
            buttonType="modalBack"
            type="button"
            onClick={() => setModalPhase(1)}
          >
            BACK
          </Button>
        </div>

        <div className="flex flex-col gap-6 justify-center items-center h-full pb-10">
          <div className="text-5xl pb-20">DEPOSITS</div>
          <div className="flex flex-col gap-2 items-center w-full">
            <div className="w-[70%] flex justify-center items-center gap-2 mb-4">
              <input
                className="text-white border-[1px] py-2 bg-[#262626]
            rounded-md text-2xl  outline-none px-6 w-[70%]"
                type="number"
                required
                placeholder="Deposit $Gamba"
                spellCheck="false"
                onChange={handleChange}
                value={depositAmount}
                onWheel={(e) => e.target.blur()}
              />
              <Button
                type="button"
                buttonType="maxWithdrawal"
                onClick={handleMax}
              >
                MAX
              </Button>
            </div>

            {isApproved ? (
              <Button
                type="button"
                buttonType="confirmDeposit"
                onClick={() => makeDeposit?.()}
              >
                CONFIRM DEPOSIT
              </Button>
            ) : (
              <Button
                type="button"
                buttonType={
                  approvalLoading ? "processingWithdrawal" : "confirmDeposit"
                }
                onClick={approvalLoading ? null : handleApprove}
              >
                {approvalLoading ? "APPROVING..." : "APPROVE"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
