import React from "react";

const BUTTON_TYPE_CLASSES = {
  light: "lightModeBtn",
  dark: "darkModeBtn",
  wallet: "walletBtn",
  walletheader: "walletHeaderBtn",
  xbtn: "bankModalX",
  modalmenu: "modalMenuBtn",
  bet: "betBtn",
  rouletteBet: "rouletteBet",
  flip: "flipBtn",
  flipNonActive: "flipBtnNonActive",
  headsTailsActive: "headsTailsActiveBtn",
  headsTailsNonActive: "headsTailsNonActiveBtn",
  modalBack: "bankModalBack",
  confirmDeposit: "confirmDepositBtn",
  maxWithdrawal: "maxWithdrawalBtn",
  processingWithdrawal: "withdrawProcessing",
};

const Button = ({ children, buttonType, ...otherProps }) => {
  return (
    <button
      className={`flex items-center ${BUTTON_TYPE_CLASSES[buttonType]}`}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
