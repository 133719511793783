import React, { useContext, useState } from "react";
import Button from "../Buttons/Button";
import { AppContext } from "../../Context/app.context";
import { ReactComponent as Mute } from "../../Assets/Mute.svg";
import { ReactComponent as Unmute } from "../../Assets/Unmute.svg";
import { ReactComponent as Trophy } from "../../Assets/Trophy.svg";
import { ReactComponent as MsgTriangle } from "../../Assets/messagetriangle.svg";
import { motion } from "framer-motion";
// import { ReactComponent as History } from "../../Assets/History.svg";
import homelogo from "../../Assets/headerlogo.png";
import logo from "../../Assets/coin.png";
import { Link } from "react-router-dom";
import { ConnectWallet } from "../Buttons/ConnectWallet";
import { useAccount } from "wagmi";
import {formatNumberToShort} from "../../balanceHelper";

const fadeAni = {
  offScreen: {
    opacity: 0,
  },
  onScreen: {
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.1,
    },
  },
};

const Header = () => {
  const { isMuted, setMuted, setModalPhase, balance } = useContext(AppContext);
  const { isConnected } = useAccount();
  const [isBalanceMsgShown, setBalanceMsgShown] = useState(false);

  return (
    <div className="font-gothic pb-14 max-w-screen-2xl mx-auto fixed top-4 z-20">
      <div className="flex justify-between">
        <div className="flex gap-2 fixed left-4 tab:left-6">
          {/* ====================== SOUND BUTTON ====================== */}
          <div className="tab:block hidden">
            <Button
              type="button"
              buttonType="dark"
              onClick={() => setMuted(!isMuted)}
            >
              {isMuted ? (
                <Mute className="h-5 w-5" style={{ fill: "#fff" }} />
              ) : (
                <Unmute className="h-5 w-5" style={{ fill: "#fff" }} />
              )}
            </Button>
          </div>

          {/* =================== LIGHT MODE / DARK MODE BUTTON  =================== */}

          <Link to="/">
            <Button type="button" buttonType="dark">
              HOME{" "}
              <img
                src={homelogo}
                alt="gamba-logo"
                className="h-3 w-3 mb-[1.3px]"
              />
            </Button>
          </Link>
        </div>


        <div className="flex gap-2 fixed right-2 tab:right-6">
          {/* ====================== GAMES BUTTON ====================== */}
          <div className="tab:block hidden">
            <Button type="button" buttonType="dark">
              GAMES
            </Button>
          </div>

          {/* ====================== LEADER BOARD BUTTON ====================== */}
          <Link to="/leaderboard">
            <Button type="button" buttonType="dark">
              LEADERBOARD
              <Trophy
                className="h-3 w-3 tab:block hidden"
                style={{ fill: "#fff" }}
              />
            </Button>
          </Link>

          {/* ====================== CONNECT BUTTON ====================== */}

          {isConnected ? (
            <div className="">
              <Button
                onClick={() => setModalPhase(1)}
                onMouseEnter={() => setBalanceMsgShown(true)}
                onMouseLeave={() => setBalanceMsgShown(false)}
                type="button"
                buttonType="walletheader"
              >
                Balance: {formatNumberToShort(balance)}
                <img src={logo} alt="gamba-logo" className="h-4 w-4" />
              </Button>

              <motion.div
                variants={fadeAni}
                initial="offScreen"
                animate={isBalanceMsgShown ? "onScreen" : "offScreen"}
                className="flex flex-col justify-center items-center pt-[4px]"
              >
                <MsgTriangle className="w-10" />
                <div className="bg-white w-[140px] rounded-md px-[6px] text-center leading-tight text-[16px]">
                  Click here to DEPOSIT, WITHDRAW or SIGNOUT
                </div>
              </motion.div>
            </div>
          ) : (
            <ConnectWallet />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
