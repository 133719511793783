import React, { useEffect, useState } from "react";
import logo from "../../Assets/coin.png";
import glass from "../../Assets/glass.gif";
import bottle from "../../Assets/bottle.gif";
import cigar from "../../Assets/cigar.gif";
import LeaderboardStats from "../../Components/LeaderboardStats/LeaderboardStats";
import axios from "axios";

const LeaderBoardPage = () => {
  const [stats, setStats] = useState([
    {
      walletAddress: "Loading...",
      balance: "Loading...",
      lastTransaction: "1min ago",
    },
  ]);
  useEffect(() => {
    async function setLeaderboard() {
      await axios
        .get(`https://api.gambacoin.com/getLeaderboard`)
        .then(async (response) => {
          setStats(response.data);
        })
        .catch(() => {});
    }
    setLeaderboard();
  }, []);
  return (
    <div className="text-white bg-[#4B3131] h-screen">
      <div className="relative top-0">
        <div className="h-[300px] bg-[#241C36] w-full relative">
          <div className="flex justify justify-start pl-24 pt-24 gap-4">
            <img src={logo} alt="gamba-logo" className="h-16" />
            <p className="text-5xl">GAMBA Leaderboard</p>
          </div>

          <div className="w-full pr-32 flex justify-end absolute bottom-0 items-end gap-10">
            <img src={cigar} alt="cigar" className="h-[60px]" />
            <img src={glass} alt="scotch-glass" className="h-[60px]" />
            <img src={bottle} alt="bottle" className="h-[175px]" />
          </div>
        </div>
      </div>

      {/* PAGE SEPARATOR */}
      <div className="h-[6px] w-full bg-[#D3BD9D]" />
      <div className="h-[18px] w-full bg-[#A57B61]" />
      <div className="h-[70px] w-full bg-[#8B6152] text-xl text-[#321A0F] ">
        <p className="max-w-5xl mx-auto text-2xl flex items-center pl-2 h-full">
          TOP Degens
        </p>
      </div>
      <div className="h-[6px] w-full bg-[#6B4A43]" />

      {/* LIVE RANKING */}
      <div className="bg-[#4B3131] ">
        <LeaderboardStats stats={stats} />
      </div>
    </div>
  );
};

export default LeaderBoardPage;
