import React, { useContext, useState } from "react";
import Button from "../Buttons/Button";
import homelogo from "../../Assets/headerlogo.png";
import axios from "axios";
import { AppContext } from "../../Context/app.context";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { getAuthToken } from "../../authentificationHelper";
import {
  casinoContractABI,
  casinoContractAddress,
} from "../../Utils/Casino/casinoConstant";
import { useContract, useSigner } from "wagmi";

const BASEURL = "https://api.gambacoin.com";

async function sendWithdrawRequest(amount, manualOverrideKey) {
  const numberAmount = Number(amount);
  // TODO: add toast for these cases
  if (isNaN(numberAmount)) return;
  if (numberAmount <= 0) return;

  const response = await axios.post(
    `${BASEURL}/withdraw`,
    {
      amount: Number(amount),
    },
    {
      headers: {
        Authorization: getAuthToken(),
      },
    }
  );
  const { values: request, v, r, s } = response.data;
  return [
    [
      [
        request.recipient,
        ethers.BigNumber.from(request.amount).toString(),
        request.token,
        [],
        request.blockNumber,
        request.extraParams,
      ],
      v,
      r,
      s,
    ],
    manualOverrideKey || ethers.constants.HashZero,
  ];
}

const Withdraw = ({ setModalPhase }) => {
  const [withdrawAmount, setwithdrawAmount] = useState("");
  const [showKey, setShowKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityKey, setSecurityKey] = useState("");
  let toastId = React.useRef(null);
  const { balance, setBalance } = useContext(AppContext);
  const { data: signer } = useSigner();
  // ================== 📝 CONTRACT WRITES 📝 ===================
  // $GAMBA Withdrawal Contract Write
  // const { config: casinoConfig } = usePrepareContractWrite({
  //   address: casinoContractAddress,
  //   abi: casinoContractABI,
  //   functionName: "withdraw",
  //   // args: ,
  // });
  const contract = useContract({
    address: casinoContractAddress,
    abi: casinoContractABI,
    signerOrProvider: signer,
    // args: ,
  });

  // const { isSuccess: txSuccess } = useWaitForTransaction({
  //   hash: withdrawData?.hash,
  // });

  async function setUser() {
    await axios
      .get(`https://api.gambacoin.com/getUser`, {
        headers: {
          authorization: getAuthToken(),
        },
      })
      .then(async (response) => {
        setBalance(response.data.balance);
      })
      .catch(() => {});
  }

  const handleChange = (event) => {
    setwithdrawAmount(event.target.value);
  };

  const handleSecurityKeyChange = (event) => {
    console.log("key", event.target.value);
    setSecurityKey(event.target.value);
  };

  const handleMax = () => {
    setwithdrawAmount(balance);
  };

  const handleWithdraw = React.useCallback(async () => {
    //withdrawAmount the amount of tokens the user entered
    //address the address of the user
    //https://wagmi.sh/react/getting-started
    //signMessage already done in App.js
    try {
      // throw new Error({ response: { status: 401 } });
      if (Number(balance) < Number(withdrawAmount)) {
        toast.error("Insufficient Balance For This Withdrawal", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          bodyClassName: "toastBodyError",
        });
      } else {
        // TODO: add a loader
        setLoading(true);

        console.log(withdrawAmount, securityKey ? securityKey : undefined);
        const params = await sendWithdrawRequest(
          withdrawAmount,
          securityKey ? securityKey : undefined
        );
        console.log("params from withdraw request", params);
        const estimateGas = await contract.estimateGas.withdraw(...params);
        // // TODO if it fails here then maybe un ban user here
        console.log("estimate gas", estimateGas);
        const request = await contract.withdraw(...params, {
          gasLimit: estimateGas,
        });
        await toast.promise(request.wait(2), {
          error: "There was an error processing your request",
          success:
            "You have withdrawn successfully. Your account may be locked for 5 min",
          pending: "Waiting for the transaction to get mined",
        });

        setUser();
        // write({ args: [...params] });
        //  await response.wait(2);
        // TODO: add a toast to let them know that their account is locked until the payment gets finalize on the backend
        // Also to open a ticket if it is taking more than 10 minutes for this to resolve
        //  console.log(response.)
      }
    } catch (err) {
      // if (axios.isAxiosError(err)) {
      //   if (err.response.status === 401) {
      //     toast.error(
      //       "Account in cooldown mode please try again 5 minutes later",
      //       {
      //         position: "top-center",
      //         autoClose: 3000,
      //         hideProgressBar: true,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "dark",
      //         bodyClassName: "toastBodyError",
      //       }
      //     );
      //   }
      // } else {
      //   toast.error("Oops something went wrong", {
      //     position: "top-center",
      //     autoClose: 3000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "dark",
      //     bodyClassName: "toastBodyError",
      //   });
      // }
    } finally {
      setLoading(false);
    }
  }, [balance, contract, securityKey, withdrawAmount]);
  return (
    <div>
      <div className="bg-[#262626] text-white rounded-3xl px-3 w-[90%] max-w-lg border-[1px] border-[#A4A4A4] text-[17px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        {/* MODAL HEADER */}
        <div className="pb-6">
          <img
            src={homelogo}
            alt="gamba-logo"
            className="w-8 h-8 relative top-4 left-4"
          />
          <Button
            buttonType="modalBack"
            type="button"
            onClick={() => setModalPhase(1)}
          >
            BACK
          </Button>
        </div>

        <div className="flex flex-col gap-6 justify-center items-center h-full pb-10">
          <div className="text-5xl pb-20">WITHDRAWALS</div>
          <div className="flex flex-col gap-2 items-center w-full">
            <input
              className="text-white border-[1px] py-2 bg-[#262626]
            rounded-md text-2xl outline-none mb-4 px-6 w-[65%]"
              type="text"
              required
              placeholder="Security Key"
              spellCheck="false"
              onChange={handleSecurityKeyChange}
              value={securityKey}
              onWheel={(e) => e.target.blur()}
            />

            <div className="w-[70%] flex justify-center items-center gap-2 mb-4">
              <input
                className="text-white border-[1px] py-2 bg-[#262626]
            rounded-md text-2xl outline-none px-6 w-[70%]"
                type="number"
                required
                placeholder="Withdraw $Gamba"
                spellCheck="false"
                onChange={handleChange}
                value={withdrawAmount}
                onWheel={(e) => e.target.blur()}
              />

              <Button
                type="button"
                buttonType="maxWithdrawal"
                onClick={handleMax}
              >
                MAX
              </Button>
            </div>

            <Button
              type="button"
              buttonType={loading ? "processingWithdrawal" : "confirmDeposit"}
              onClick={loading ? null : handleWithdraw}
            >
              {loading ? "WITHDRAWING..." : "CONFIRM WITHDRAWAL"}
            </Button>
            <button
              className="btn btn-ghost"
              onClick={() => setShowKey((prev) => !prev)}
            >
              {showKey ? "Hide Key" : "Show Key"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
