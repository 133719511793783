import React, { useState } from "react";
import Button from "../Buttons/Button";
import homelogo from "../../Assets/headerlogo.png";
import { ReactComponent as Disconnect } from "../../Assets/disconnect.svg";
import { ReactComponent as Withdrawl } from "../../Assets/withdrawl.svg";
import { ReactComponent as Deposit } from "../../Assets/deposit.svg";
import { ReactComponent as Submit } from "../../Assets/usernamesubmit.svg";
import { useDisconnect } from "wagmi";
import axios from "axios";
import {
  getAuthToken,
  removeAuthToken,
} from "../../authentificationHelper";

const ModalMenu = ({ setModalPhase }) => {
  const { disconnect } = useDisconnect();
  const [username, setUsername] = useState();
  const [status, setStatus] = useState();
  function handleRemoveCookie() {
    removeAuthToken();
  }

  const handleChange = (event) => {
    const regex = /^[a-zA-Z0-9_. -]{1,15}$/;
    const isValid = regex.test(event.target.value);
    if (isValid || event.target.value === "") {
      setUsername(event.target.value);
    }
  };

  const handleOnClickUsername = () => {
    if (username.length >= 3) {
      axios
        .post(
          `https://api.gambacoin.com/setUsername`,
          {
            username: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: getAuthToken(),
            },
          }
        )
        .then(() => {
          setModalPhase(0);
          setStatus("");
        })
        .catch((error) => {
          if (error.response.status === 501) {
            setStatus("Please only use A-Z, a-z, 0-9, -, _ & Length 3-15");
          } else if (error.response.status === 401) {
            setStatus("Unauthorized");
          } else if (error.response.status === 502) {
            setStatus("Username already exists!");
          } else if (error.response.status === 504) {
            setStatus("No insults!!!");
          } else {
            setStatus("Something went wrong.");
          }
        });
    }
  };

  return (
    <div>
      <div className="bg-[#262626] text-white rounded-3xl px-3 w-[90%] max-w-lg border-[1px] border-[#A4A4A4] text-[17px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        {/* MODAL HEADER */}
        <div className="pb-10">
          <img
            src={homelogo}
            alt="gamba-logo"
            className="w-8 h-8 relative top-4 left-4"
          />
          <Button
            buttonType="xbtn"
            type="button"
            onClick={() => setModalPhase(0)}
          >
            X
          </Button>
        </div>

        <div className="flex flex-col gap-10 justify-center items-center h-full pb-10">
          <div className="flex justify-between gap-2 items-center w-[210px]">
            <input
              className="text-white border-[1px] py-[0.25rem] bg-[#262626]
      rounded-md text-2xl  outline-none px-6 w-[150px]"
              required
              placeholder="Username"
              spellCheck="false"
              onChange={handleChange}
              value={username}
            />
            <Button
              onClick={() => {
                handleOnClickUsername();
              }}
            >
              <Submit className="h-12 w-12" style={{ fill: "#fff" }} />
            </Button>
          </div>
          <div className="text-red-500 text-1xl text-center">{status}</div>

          <Button
            onClick={() => setModalPhase(2)}
            type="button"
            buttonType="modalmenu"
          >
            DEPOSIT
            <Deposit className="h-6 w-6" />
          </Button>

          <Button
            onClick={() => setModalPhase(3)}
            type="button"
            buttonType="modalmenu"
          >
            WITHDRAW
            <Withdrawl className="h-6 w-6" />
          </Button>

          <Button
            onClick={() => {
              disconnect();
              setModalPhase(0);
              handleRemoveCookie();
            }}
            type="button"
            buttonType="modalmenu"
          >
            DISCONNECT
            <Disconnect className="h-6 w-6" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalMenu;
