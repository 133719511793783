import React, { useState } from "react";
import logo from "../../Assets/logo.png";
import CoinFlip from "../../Components/CoinFlip/CoinFlip";
import Roulette from "../../Components/Roulette/Roulette";

const Home = () => {
  const [isShown, setShown] = useState(0);

  return (
    <div className="w-full h-full">
      <CoinFlip isShown={isShown} setShown={setShown} />
      <div className="w-full h-[20%] bg-homepageMiddle bg-cover bg-center flex justify-center items-center">
        <img src={logo} alt="gamba-logo" className="tab:h-32 h-[60px]" />
      </div>
      <Roulette isShown={isShown} setShown={setShown} />
    </div>
  );
};

export default Home;
