import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const questions = [
  {
    id: 1,
    headerMsg: "How Can I Deposit Funds ?",
    bodyMsg:
      "Click on the balance button at the top in order to navigate to the deposit area.",
  },
  {
    id: 2,
    headerMsg: "How Can I Withdraw My Winnings/Balance ?",
    bodyMsg:
      "Click on the balance button at the top in order to navigate to the withdrawal area.",
  },
  {
    id: 3,
    headerMsg: "Which Currencies Can I Wager ?",
    bodyMsg:
      "At the current moment wagers are only accepted in our native currency of $GAMBA.",
  },
  {
    id: 4,
    headerMsg: "Where Can I Buy $GAMBA Tokens ?",
    bodyMsg:
      "$GAMBA Tokens can be purchased through decentralized exchanges such as UNISWAP. Simply enter the contract address and the token you wish to swap from.",
  },
  {
    id: 5,
    headerMsg: "How Much $GAMBA Can I Wager Per Bet ?",
    bodyMsg: "There currently is no limit, your balance is your cap.",
  },
];

const Faq = ({ isOpen }) => {
  const [QuestionOpen, setQuestionOpen] = useState(0);

  const handleOpen = (value) => {
    setQuestionOpen(QuestionOpen === value ? 0 : value);
  };

  const FaqAccordions = () => {
    return (
      <div className="space-y-4 h-[425px] text-white flex flex-col justify-center items-center">
        {questions.map((faq) => {
          return (
            <Accordion
              key={faq.id}
              className=" max-w-3xl  "
              open={QuestionOpen === faq.id}
              icon={<Icon id={faq.id} open={QuestionOpen} />}
            >
              <AccordionHeader
                className="text-white font-gothic text-[18px] hover:text-gray-200"
                onClick={() => handleOpen(faq.id)}
              >
                {faq.headerMsg}
              </AccordionHeader>
              <AccordionBody className="text-white font-bold">
                {faq.bodyMsg}
              </AccordionBody>
            </Accordion>
          );
        })}
      </div>
    );
  };

  return (
    <div className="">
      <div
        // onClick={() => setOpen(0)}
        className={
          isOpen === 1
            ? "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[500px] w-[400px] bg-[#262626] border-[1px] border-[#A4A4A4] z-50"
            : "hidden"
        }
      >
        <p className="text-white text-4xl flex justify-center items-center pt-2">
          FAQ
        </p>

        <div className="px-4">
          <FaqAccordions />
        </div>
      </div>
    </div>
  );
};

export default Faq;
