export function formatNumberToShort(number) {
    const billion = 1e9;
    const million = 1e6;
    const thousand = 1e3;

    const num = Number(number);

    if (num >= billion) {
        return (Math.floor(num / billion * 10) / 10).toFixed(2) + 'b';
    } else if (num >= million) {
        return (Math.floor(num / million * 10) / 10).toFixed(2) + 'm';
    } else if (num >= thousand) {
        return (Math.floor(num / thousand * 10) / 10).toFixed(2) + 'k';
    }else{
        return num.toFixed(0)
    }
}

export function formatNumberToLong(number) {
    const billion = 1e9;
    const million = 1e6;
    const thousand = 1e3;



    if (number.endsWith('b')) {
        const num = Number(number.split("b")[0]);
        return (num * billion).toFixed(0);
    } else if (number.endsWith('m')) {
        const num = Number(number.split("m")[0]);
        return (num * million).toFixed(0);
    } else if (number.endsWith('k')) {
        const num = Number(number.split("k")[0]);
        return (num * thousand).toFixed(0);
    }

    return Number(number).toFixed(0);
}