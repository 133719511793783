import React from "react";
import cards from "../../Assets/cards.png";
import money from "../../Assets/money.png";
import flip from "../../Assets/flip.gif";
import { Link } from "react-router-dom";

// THIS IS THE HOMEPAGE ANIMATIONS PRIOR TO ENTERING THE GAME
const CoinFlip = ({ isShown, setShown }) => {
  return (
    <Link to="/coinflip">
      <div
        onMouseEnter={() => setShown(1)}
        onMouseLeave={() => setShown(0)}
        className="w-full  h-[40%] relative cursor-pointer"
      >
        <div
          className={
            isShown === 1
              ? "hidden"
              : "fixed bg-black/50 w-full h-[40%] top-0 z-10 text-6xl tab:text-8xl text-[#ddd] flex justify-center items-center tracking-[8px]"
          }
        >
          COINFLIP
        </div>
        {/* VISUAL ASSETS */}
        <img
          src={cards}
          alt="cards"
          className="fixed top-0 right-0 z-[5] h-[150px] tab:h-[325px]"
        />
        <img
          src={money}
          alt="cards"
          className="fixed top-0 left-0 z-[5] h-[125px] tab:h-[275px]"
        />
        <img
          src={flip}
          alt="flip"
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-[5] 
          h-[200px] tab:h-[475px]"
        />
      </div>
    </Link>
  );
};

export default CoinFlip;
