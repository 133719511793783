import React, { useContext, useState, useRef } from "react";
import Button from "../Buttons/Button";
import axios from "axios";
import { AppContext } from "../../Context/app.context";
// import TailsWin from "../../Assets/Video/TailsWin.mp4";
// import HeadsWin from "../../Assets/Video/HeadsWin.mp4";
import TailsWin from "../../Assets/Video/Tails_wins.webm";
import HeadsWin from "../../Assets/Video/Heads_wins.webm";
import {getAuthToken} from "../../authentificationHelper";
import {formatNumberToShort, formatNumberToLong} from "../../balanceHelper";

const BetArea = () => {
  const [currentCoinsBet, setCurrentCoinsBet] = useState(1);
  const [lastCoinsBet, setLastCoinsBet] = useState(1);
  const [currentBet, setCurrentBet] = useState(1);
  const [lastBet, setLastBet] = useState();
  const [status, setStatus] = useState("");
  const [winner, setWinner] = useState(1);
  const [isFlipping, setFlipping] = useState(false);
  const [flipResult, setFlipResult] = useState(false);
  const [roundWon, setRoundWon] = useState(undefined);
  const [updatedBalance, setUpdatedBalance] = useState(-1)
  const { balance, setBalance, isMuted } = useContext(AppContext);

  const videoRefHeads = useRef(null);
  const videoRefTails = useRef(null);

  const playFlipHeads = () => {
    videoRefHeads.current.play();
  };

  const playFlipTails = () => {
    videoRefTails.current.play();
  };

  const handleChange = (event) => {
    if(event.target.value === ""){
      setCurrentCoinsBet(0)
    }else if (formatNumberToLong(balance) >= Number(formatNumberToLong(event.target.value))) {
      setCurrentCoinsBet(formatNumberToLong(event.target.value));
    }
  };

  const handleMultiplyChange = (multiplier) => {
    if (multiplier === "1/2") {
      if (currentCoinsBet / 2 < balance && currentCoinsBet / 2 >= 1) {
        setCurrentCoinsBet(currentCoinsBet / 2);
      } else {
        setCurrentCoinsBet(1);
      }
    }
    if (multiplier === "Last bet") {
      if (lastCoinsBet > 0) {
        setCurrentCoinsBet(lastCoinsBet);
      }
    }
    if (multiplier === "Max") {
      setCurrentCoinsBet(balance);
    }
    if (multiplier === "2x") {
      if (currentCoinsBet * 2 < balance) {
        setCurrentCoinsBet(currentCoinsBet * 2);
      } else {
        setCurrentCoinsBet(balance);
      }
    }
    if (multiplier === "5x") {
      if (currentCoinsBet * 5 < balance) {
        setCurrentCoinsBet(currentCoinsBet * 5);
      } else {
        setCurrentCoinsBet(balance);
      }
    }
  };

  const setStatusWithTimeout = (status) => {
    setStatus(status);
    setTimeout(() => {
      setStatus("");
    }, 3000);
  };

  const handleBets = async () => {
    if (!currentCoinsBet > 0) {
      setStatusWithTimeout("Please bet more than 0 coins.");
      setFlipping(false)
      return;
    }
    await axios
      .get(`https://api.gambacoin.com/coinflip`, {
        headers: {
          authorization: getAuthToken()
        },
        params: {
          bet: currentBet,
          coinsBet: currentCoinsBet,
        },
      })
      .then(async (response) => {
        setUpdatedBalance(response.data.updatedBalance)
        if (currentBet === 0) {
          if (response.data.result) {
            setWinner(0);
            playFlipTails();
            setRoundWon(true);
          } else {
            setWinner(1);
            playFlipHeads();
            setRoundWon(false);
          }
        }
        if (currentBet === 1) {
          if (response.data.result) {
            setWinner(1);
            playFlipHeads();
            setRoundWon(true);
          } else {
            setWinner(0);
            playFlipTails();
            setRoundWon(false);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setStatusWithTimeout(
            "Action currently blocked. Please try again later"
          );
          setFlipping(false)
          setBalance(updatedBalance)
        } else if (error.response.status === 400) {
          setStatusWithTimeout("Invalid parameters. Please try again later");
          setFlipping(false)
          setBalance(updatedBalance)
        } else if (error.response.status === 401) {
          window.location.reload();
        } else if (error.response.status === 501) {
          setStatusWithTimeout("Invalid bet. Please only bet coins you own!");
          setFlipping(false)
          setBalance(updatedBalance)
        }else {
          setStatusWithTimeout("Something went wrong.");
          setFlipping(false)
          setBalance(updatedBalance)
        }
      });
    await setLastCoinsBet(currentCoinsBet);
  };

  return (
    <div className="">
      {/* <input
        type="text"
        className=" text-white border-[1px] py-2 bg-[#262626]
            rounded-md text-2xl  outline-none px-6 w-[400px]"
        placeholder="Bet Amount"
        spellCheck="false"
        // onChange={(event) => setBetAmount(event.target.value)}
      /> */}
      <video
        ref={videoRefHeads}
        onEnded={() => {
          setFlipping(false)
          setBalance(updatedBalance)
          if(lastCoinsBet > updatedBalance){
            setCurrentCoinsBet(updatedBalance)
          }
        }
        }
        muted={isMuted}
        playsInline
        preload="auto"
        src={HeadsWin}
        className={winner === 1 ? "h-52 mx-auto" : "hidden"}
        type="video/mp4"
      />

      <video
        ref={videoRefTails}
        onEnded={() => {
          setFlipping(false)
          setBalance(updatedBalance)
          if(lastCoinsBet > updatedBalance){
            setCurrentCoinsBet(updatedBalance)
          }
        }}
        muted={isMuted}
        playsInline
        preload="auto"
        src={TailsWin}
        className={winner === 0 ? "h-52 mx-auto" : "hidden"}
        type="video/mp4"
      />

      <div className="pt-10 flex justify-center ">
        <Button
          onClick={() => setCurrentBet(1)}
          type="button"
          buttonType={
            currentBet === 1 ? "headsTailsActive" : "headsTailsNonActive"
          }
        >
          HEADS
        </Button>

        <Button
          onClick={() => setCurrentBet(0)}
          type="button"
          buttonType={
            currentBet === 0 ? "headsTailsActive" : "headsTailsNonActive"
          }
        >
          TAILS
        </Button>
      </div>

      <div className="h-20 text-white text-4xl text-center pb-5 pt-5">
        {isFlipping === true
          ? ""
          : roundWon === true
          ? "You won!"
          : roundWon === false
          ? "You lost!"
          : ""}
      </div>

      <input
        className="text-white border-[1px] py-2 bg-[#262626]
      rounded-md text-2xl  outline-none px-6 w-[400px]"
        required
        placeholder="Bet Amount"
        spellCheck="false"
        onChange={handleChange}
        value={currentCoinsBet !== 0 ? formatNumberToShort(currentCoinsBet) : ""}
      />
      <div className="text-red-500 text-1xl text-center pt-2">{status}</div>

      <div className=" flex gap-6 pt-4 justify-center">
        <Button
            onClick={() => handleMultiplyChange("1/2")}
            type="button"
            buttonType="bet"
        >
          1/2
        </Button>

        <Button
          onClick={() => handleMultiplyChange("2x")}
          type="button"
          buttonType="bet"
        >
          X2
        </Button>

        <Button
          onClick={() => handleMultiplyChange("5x")}
          type="button"
          buttonType="bet"
        >
          X5
        </Button>

        <Button
          type="button"
          buttonType="bet"
          onClick={() => handleMultiplyChange("Max")}
        >
          MAX
        </Button>
      </div>

      <div className="flex  justify-center pt-8">
        <Button
          type="button"
          buttonType={isFlipping ? "flipNonActive" : "flip"}
          disabled={isFlipping}
          onClick={() => {
            setFlipping(true);
            handleBets();
          }}
        >
          {isFlipping ? "GOOD LUCK..." : "FLIP"}
        </Button>
      </div>
    </div>
  );
};

export default BetArea;
