import React, { useContext } from "react";
import { AppContext } from "../../Context/app.context";
import ModalMenu from "./ModalMenu";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";

const BankModal = () => {
  const { modalPhase, setModalPhase } = useContext(AppContext);
  return (
    <div>
      <div
        className={
          modalPhase === 0
            ? "hidden"
            : "fixed bg-black/50 w-full h-full top-0 z-20"
        }
      />
      {modalPhase === 1 ? (
        <div className="">
          {/* BACKGROUND OPACITY SET */}

          {/* ACTUAL MODAL */}
          <ModalMenu setModalPhase={setModalPhase} />
        </div>
      ) : (
        <></>
      )}

      {modalPhase === 2 ? <Deposit setModalPhase={setModalPhase} /> : <></>}
      {modalPhase === 3 ? <Withdraw setModalPhase={setModalPhase} /> : <></>}
    </div>
  );
};

export default BankModal;
