import React from "react";
import table from "../../Assets/table.png";
import roulette from "../../Assets/roulette.gif";
import { Link } from "react-router-dom";

// THIS IS THE HOMEPAGE ANIMATIONS PRIOR TO ENTERING THE GAME
const Roulette = ({ isShown, setShown }) => {
  return (

    <Link to="/">
      <div
        onMouseEnter={() => setShown(2)}
        onMouseLeave={() => setShown(0)}
        className="w-full  h-[40%] absolute bottom-0 cursor-pointer"
      >
        <div
          className={
            isShown === 2
              ? "fixed w-full h-[40%] bottom-0 z-10 text-8xl text-[#ddd] flex justify-center items-center tracking-[8px] "

              : "fixed bg-black/50 w-full h-[40%] bottom-0 z-10 text-6xl tab:text-8xl text-[#ddd] flex justify-center items-center tracking-[8px] "
          }
        >
            {isShown === 2 ? "COMING SOON" : "ROULETTE"}
        </div>
        <img
          src={table}
          alt="cards"
          className="fixed bottom-0 left-0 z-[5] h-[175px] tab:h-[325px]"
        />
        <img
          src={roulette}
          alt="cards"
          className="fixed bottom-0 right-0 z-[5] h-[150px] tab:h-[275px]"
        />
      </div>
    </Link>
  );
};

export default Roulette;
