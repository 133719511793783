import React from "react";
import coin from "../../Assets/coin.png";
import { ReactComponent as StatSeparator } from "../../Assets/StatsSeparator.svg";
import {formatNumberToShort} from "../../balanceHelper";


const LiveLeaderboard = (props) => {
  return (
    <div className="">
      {props.stats.map((leader, index) => {
        return (
            <div
                key={index}
                className="flex border-b-[0.1px] border-[#33333E] py-4"
            >
              <div className="flex pl-2 gap-2 w-[60%]">
                <img src={coin} alt="pfp" className="w-6 h-6" />
                <div>{`${index + 1}. ${leader.walletAddress}`}</div>
              </div>

              <div className="w-[20%] flex items-center gap-2">
                <StatSeparator className="w-[8px] h-4" />
                {formatNumberToShort(leader.balance)}
              </div>

              <div className="pr-4 w-[20%] text-end">{leader.lastTransaction}</div>
            </div>
        );
      })}
    </div>
  );
};

const LeaderboardStats = (props) => {
  return (
    <div className="pb-8">
      <div className="max-w-5xl mx-auto flex bg-[#3A2116]">
        <div className="w-[10px]  h-[500px] bg-[#3A2116]" />
        <div className=" w-full bg-[#241C36]">
          {/* START */}
          <div className="text-[14px] font-serif py-4 flex border-b-[0.1px] border-[#33333E]">
            <div className="w-[60%] pl-2">Degen</div>

            <div className="w-[20%]">Current coins</div>
            <div className="pr-4 w-[20%] text-end">Last Flip</div>
          </div>
          <LiveLeaderboard stats={props.stats} />
        </div>
        <div className="w-[10px]  h-[500px] bg-[#3A2116]" />
      </div>
    </div>
  );
};

export default LeaderboardStats;
